import h from '@/api/http/http'

const http = h.http;
const get = h.get;
const post = h.post;

const schoolCustom = {
    login(params) {
        return http.post('/login', params);
    },
    // 获取下拉框院校列表
    getSendingInstitution() {
        return get('/sending/get_recentSendingInstitution');
    },
    // 获取院校列表
    getAllInstitutions(params) {
        return http.get('/school/getAllInstitutions', {
            params: params
        });
    },
    // 添加二级单位
    postAddChildrenOrg(params){
        return post('/sending/add_childrenOrg',params);
    },
    // 添加学位类型
    postAddDegree(params){
        return post('/sending/add_degree',params);
    },
    // 获取学位类型
    getDegree(params) {
        return http.get('/school/getDegree', {
            params: params
        });
    },
    // 删除学位类型
    postDeleteDegree(params) {
        return post('/school/deleteDegree', params);
    },
    // 修改二级单位名称
    modifyInstitutionName(params){
        return post('/school/modifyInstitutionName',params);
    },
    // 修改机构是否匿名
    modifyInstitutionIsDisplay(params){
        return post('/school/modifyInstitutionIsDisplay',params);
    },
    // 获取评阅书模板
    getTemplate(params) {
        return http.get('/school/reviewTemplate', {
            params: params
        });
    },
    // 保存学位类型和模板
    postDegreeReview(params) {
        return http.post("/school/DegreeReview/save", params);
    },
    // 获取命名规则字段
    getNamingRule(params) {
        return http.get("/school/namingRule/attrs", {
            params: params
        });
    },
    // 保存命名规则
    postNamingRule(params) {
        return post("/school/namingRule/save", params);
    },
    // 获取信息表字段
    getStatisticsFields(params){
        return get("/school/statistics/fields",params);
    },
    // 保存信息表字段
    postStatisticsFields(params){
        return post('/school/statistics/save',params);
    },
    // 获取专家遴选选项
    getFilterOptions(params) {
        return http.get("/school/filter/options", {
            params: params
        });
    },
    // 保存遴选条件
    postFilter(params) {
        return http.post("/school/filter/save", params);
    },
    // 根据学校id获取学科评估
    getSubjects(params) {
        return http.get("/school/subjects", {
            params: params
        });
    },
    // 保存学科评估以及对应的送审评估等级
    postSubjects(params) {
        return http.post("/school/subjects/save", params);
    },
    // 获取支付角色
    getPayRole() {
        return get('/school/pay/role');
    },
    // 获取送审模式
    getSendingMode() {
        return get('/school/sending/mode');
    },
    // 保存支付
    postPay(params) {
        return post("/school/pay/save", params);
    },
    // 保存支付模式以及其他内容
    postOther(params) {
        return post("/school/other/save", params);
    },
    // 获取某院校送审规则预览
    getPreviews(params) {
        return get("/school/get_previews", params);
    },
    // 获取某个送审策略的总览
    getOverview(params) {
        return get("/school/get_overview", params);
    },
    // 删除送审规则
    postDeleteSendingRule(params) {
        return post('/school/delete_sendingRule',params);
    },
    // 查看第一页
    getSendingPolicy1(params){
        return get('/school/sendingPolicy/1',params);
    },
    // 查看第二页
    getSendingPolicy2(params){
        return get('/school/sendingPolicy/2',params);
    },
    // 查看第三页
    getSendingPolicy3(params){
        return get('/school/sendingPolicy/3',params);
    },
    // 查看第四页
    getSendingPolicy4(params){
        return get('/school/sendingPolicy/4',params);
    },
    // 查看第六页
    getSendingPolicy6(params){
        return get('/school/sendingPolicy/6',params);
    },
    // 查看第七页
    getSendingPolicy7(params){
        return get('/school/sendingPolicy/7',params);
    },
    // 获取省市县
    getLocation(){
        return get('/sending/getLocation');
    },
    //
    getAnonymousInfo(params){
        return post('/school/sendingPolicy/2_1',params);
    },
    savePlaceholderMap(params){
        console.log(params)
        return post('/school/placeholderMap/save',params);
    },
    getbatchDistributionInstitution(params){
        return post('/sending/getbatchDistributionInstitution', params)
    },
    insertSchool(params){
        return post('/school/insertSchool', params);
    },
    // 获取论文字段信息
    getEmailPaperInfos() {
        return get('/email/getEmailPaperInfos');
    },

    //获取专家字段信息
    getEmailExpertInfos() {
        return get('/email/getEmailExpertInfos');
    },

    // 修改邮件模板
    postEmailTemplate(params) {
        return post('/email/postEmailTemplate', params);
    },

    //获取邮件模板
    getEmailTemplate(params) {
        return get('/email/getEmailTemplate', params);
    },

    // 获取邮件列表
    getEmailTemplatePreviews(params) {
        return get('/email/getEmailTemplatePreviews', params);
    },

    // 添加邮件模板
    postAddEmailTemplate(params) {
        return post('/email/addEmailTemplate', params);
    },


    //删除邮件模板
    postDeleteEmailTemplate(params) {
        return post('/email/delete_EmailTemplate', params);
    },

    // 获取评阅书定制信息
    getReviewInfo(params) {
        return get('/school/getReviewInfo', params)
    },

    // 获取评阅书定制信息
    getReportInfos(params) {
        return get('/report/getReportInfos', params)
    },


    // 获取评阅书列表
    getReviewTemplatePreviews(params) {
        return get('/template/getReviewTemplatePreviews', params);
    },

    // 上传模板文件
    postUploadPatternFile(params) {
        return post("/template/uploadPatternFile", params);
    },


    // 获取质量分析报告列表
    getReportList(params) {
        return get('/report/getReportList', params);
    },

    // 添加质量分析报告
    postAddReport(params) {
        return post('/report/addReport', params);
    },

    //删除质量分析报告
    postDeleteReport(params) {
        return post('/report/deleteReport', params);
    },

    // 上传模板文件
    postUploadReportFile(params) {
        return post("/report/uploadReportFile", params);
    },

    // 下载质量分析报告
    reportDownload(params){
        return http.post('/report/reportDownload',params,{
            responseType: 'blob'
        });
    },

    // 测试质量分析报告
    reportFeasibilityVerify(params){
        return http.post("/report/reportFeasibilityVerify",params,{
            responseType: 'blob'
        });
    },
    // 生成图表
    generateChart(params){
        return http.post("/report/generateBarChart",params,{
            responseType: 'blob'
        });
    },
    // 获取院校批次
    getBatch(params)
    {
        return get('/report/get_batchByOrg',params);
    },
    // 获取院校名
    getInstitutionName(params)
    {
        return get('/report/get_institutionName',params);
    },

}

export default schoolCustom