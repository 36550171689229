<template>
    <div class="navbar">
        <!-- <div class="brand">论文评审中心</div> -->
        <!-- Hamburger -->
        <!-- <hamburger id='hamburger-container' :isActive='sidebar.opend' class="hamburger-container" @toggleClick='toggleSideBar'></hamburger> -->
        <div class="title">
            <!-- 论文送审中心 -->
            <img :src="require('@/assets/img/image.png')" alt="江苏高擎论文评审中心" height="40px;">
        </div>
        <div class="right-menu">
              <div class="right-message_item">
                  <el-popover
                    placement="right"
                    width="500"
                    trigger="click">
                  <el-table :data="message" height="425px">
                    <el-table-column width="100" property="messageTime" label="发布日期"></el-table-column>
                    <el-table-column width="100" property="institutionName" label="单位"></el-table-column>
                    <el-table-column width="100" property="messageContent" label="批次"></el-table-column>
                    <el-table-column width="100" property="isChecked" label="信息状态">
                      <template #default="{ row }">
                        {{row.isChecked == 0?"未确认":"已确认"}}
                      </template>
                    </el-table-column>
                    <el-table-column width="100" label="信息确认">
                      <template #default="{ row }">
                        <el-button v-if="row.isChecked == 0" type="primary" @click="confirmMessage(row)">确认</el-button>
                      </template>
                    </el-table-column>
                  </el-table>
                  <el-button icon="el-icon-message-solid" slot="reference" @click="getMessage()" class="mesBtn"></el-button>
                  <el-badge :value="messageNum"  slot="reference" class="mybadge right-message_item"></el-badge>
                </el-popover>
                
              </div>
            <el-dropdown class="avatar-container right-menu-item hover-effect" trigger="click">
                <div class="avatar-wrapper">
                    <!-- <img src="~@/assets/img/avatar.png" style="width:40px;height:40px;" class="user-avatar"> -->
                    <!-- <span>匿名送审员</span> -->
                    <span>{{info.institution}} {{info.name}}</span>
                    <i class="el-icon-caret-bottom" />
                </div>
                <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item divided @click.native="logout">
                        <span style="display:block;">退出</span>
                    </el-dropdown-item>
                </el-dropdown-menu>
            </el-dropdown>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
    import Hamburger from './Hamburger'
    import {setToken,getToken,removeToken} from '@/tools/auth'
    import {EventBus} from "@/store/event-bus"
    export default {
        name: 'Navbar',
        data() {
            return {
              messageNum: 1,
              message: [{
                messageTime: '2016-05-02',
                messageContent: '王小虎',
                address: '上海市普陀区金沙江路 1518 弄'
              }, {
                messageTime: '2016-05-04',
                messageContent: '王小虎',
                address: '上海市普陀区金沙江路 1518 弄'
              }, {
                messageTime: '2016-05-01',
                messageContent: '王小虎',
                address: '上海市普陀区金沙江路 1518 弄'
              }, {
                messageTime: '2016-05-03',
                messageContent: '王小虎',
                address: '上海市普陀区金沙江路 1518 弄'
              }]
            }
        },
        computed:{
            ...mapGetters([
                'sidebar',
                'info'
            ])
        },
        created() {
          this.getMessage();
        },
        methods: {
            toggleSideBar() {
                this.$store.dispatch('app/toggleSidebar')
            },
            logout() {
                this.$api.user.logout()
                .then(res=>{
                    if(res.data.code == 200)
                    {
                        removeToken();
                        window.localStorage.clear();
                        window.sessionStorage.clear();
                        // this.$store.dispatch('professor/changeIsLogin', 0);
                        this.$router.push(`/login`);
                    }
                })
            },
        //   获取提醒信息
            getMessage(){
              this.$api.user.getMessage()
                  .then(res => {
                    if (res.data.code == 200){
                      this.message = res.data.data;
                      let len = this.message.filter(item => item.isChecked == 0).length;
                      this.messageNum = len;
                    }
                  })
            },
        //   信息确认
            confirmMessage(row){
              // EventBus.$emit('getNewPaperList','123');
              // return;
              console.log(row);
              let params = {
                "batchDistributionId": row.batchDistributionId
              }
              this.$api.user.confirmMessage(params)
                  .then(res => {
                    if (res.data.code == 200){
                      this.$message.success("用户已确认信息");
                      this.getMessage();
                    }else {
                      this.$message.warning("服务器维护")
                    }
                  }).then(res => {
                    // 告知页面进行刷新
                    EventBus.$emit('getNewPaperList','');
                  })
            }
        },
        components: {
            Hamburger
        }
    }
</script>
<style scope>
    .title{
        float: left;
        font-size: 32px;
        padding-left: 20px;
        line-height: 50px;
        letter-spacing: 5px;
        padding-top: 3px;
        font-family: '楷体';
    }
    .mybadge{
      position: relative;
      bottom: 24px;
      font-size: 12px;
      left: -12px;
    }
    .mesBtn{
      position: relative;
      bottom: 9px;
      padding: 0px !important;
      background: #0000 !important;
      border: 0px !important;
      font-size: 28px !important;
      color: #fff !important;
    }
</style>
<style lang='scss' scoped>
    .navbar {
        height: 50px;
        overflow: hidden;
        position: relative;
        // background:#fff;
        background: #bf291b;
        box-shadow: 0 1px 4px rgba(0, 21, 42, 0.08);
        color: #fff;
        z-index: 1000;

        // 汉堡包
        .hamburger-container {
            line-height: 46px;
            height: 100%;
            float: left;
            cursor: pointer;
            transition: background .3s;
            -webkit-tap-highlight-color: transparent;

            &:hover {
                background: rgba(0, 0, 0, .025)
            }
        }

        //右边
        .right-menu {
            float: right;
            height: 100%;
            line-height: 50px;

            &:focus {
                outline: none;
            }

            .right-message_item{
                display: inline-block;
                height: 100%;
                color: #fff;
            }

            .right-menu-item {
                display: inline-block;
                padding: 0 8px;
                height: 100%;
                font-size: 18px;
                color: #fff;
                vertical-align: text-bottom;

                &.hover-effect {
                    cursor: pointer;
                    transition: background .3s;

                    &:hover {
                        background: rgba(0, 0, 0, .025)
                    }
                }
            }

            .avatar-container {
                margin-right: 30px;

                // .avatar-wrapper {
                //     // margin-top: 5px;
                //     position: relative;

                //     .user-avatar {
                //         cursor: pointer;
                //         width: 40px;
                //         height: 40px;
                //         border-radius: 10px;
                //     }

                //     .el-icon-caret-bottom {
                //         cursor: pointer;
                //         position: absolute;
                //         // right: -20px;
                //         top: 25px;
                //         font-size: 16px;
                //     }
                // }
            }
        }
    }

</style>