import h from '@/api/http/http'

const http = h.http;
const get = h.get;
const post = h.post;

const user = {
    // 登录
    login(params) {
        return http.post('/login', params);
    },
    // 退出
    logout() {
        return post('/logout');
    },
    // 重置密码
    resetPwd(params){
        return post('/admin/resetPassword',params);
    },
    getSession(){
        return post('/common/getSessionId');
    },
    // 添加中心端用户
    addUserWeb(params){
        return post('/user/addUserWeb',params);
    },
    // 获取提醒信息
    getMessage(){
        return post('/user/getMessage')
    },
    // 信息确认
    confirmMessage(params){
        return post('/user/confirmMessage', params)
    },
    // 获取论文工作日志
    getWorkLogs(param){
        return post('/user/getWorkLogs',param)
    }

}

export default user