import h from '@/api/http/http'

const http = h.http;
const get = h.get;
const post = h.post;

const reviewCustom = {
    // 获取评审体系列表
    getAllPatterns() {
        return get('/template/getAllPatterns');
    },
    // 新建评审模板
    postAddPattern(params) {
        return post('/template/addPattern', params);
    },
    copyReviewSystemAndTemplate(params) {
        return post('/school/copyReviewSystemAndTemplate', params);
    },
    // 修改评审模板
    postEditPattern(params) {
        return post('/template/editPattern', params);
    },
    // 删除评审模板
    postDisablePattern(params) {
        return post('/template/disablePattern', params);
    },
    // 获取评审体系
    getSinglePattern(params) {
        return get("/template/getSinglePattern", params);
    },
    // 修改模板细节
    postEditDetails(params) {
        return post('/template/editDetails', params);
    },
    // 删除模板细节
    postDisableDetail(params) {
        return post('/template/disableDetail', params);
    },
    // 删除模板细节
    postDisableDetailById(params) {
        return post('/template/disableDetailById', params);
    },

    /*
    模板定制 
    */

    // 获取论文字段信息
    getReviewPaperInfos() {
        return get('/school/getReviewPaperInfos');
    },

    //获取专家字段信息 
    getReviewExpertInfos() {
        return get('/school/getReviewExpertInfos');
    },

    // 获取评阅书定制信息
    getReviewInfo(params) {
        return get('/school/getReviewInfo', params)
    },

    // 添加评阅书模板
    postAddReviewTemplate(params) {
        return post('/template/addReviewTemplate', params);
    },

    // 获取评阅书列表
    getReviewTemplatePreviews(params) {
        return get('/template/getReviewTemplatePreviews', params);
    },

    // 上传模板文件
    postUploadPatternFile(params) {
        return post("/template/uploadPatternFile", params);
    },

    //删除评语书定制
    postDeleteReviewTemplate(params) {
        return post('/template/delete_ReviewTemplate', params);
    },
    // 测试模板
    templateFeasibilityVerify(params){
        return http.post("/template/templateFeasibilityVerify",params,{
            responseType: 'blob'
        });
    },
    // 下载模板
    templateDownload(params){
        return http.post('/template/templateDownload',params,{
            responseType: 'blob'
        });
    }


}

export default reviewCustom