import h from '@/api/http/http'

const http = h.http;
const get = h.get;
const post = h.post;

const authority = {
    // 获取全部全局权限
    getAllPermissions() {
        return get('/admin/global/getAllPermissions');
    },
    //添加角色权限
    // postAddRoleAndPermission(params) {
    //     return post('/admin/roleAndPermission/add', params);
    // },
    postAddRoleAndPermission(params) {
        return post('/authority/postAddRoleAndPermission', params);
    },
    // 获取角色类型
    getPreviewRole() {
        return get('/school/get_previewRole');
    },
    // 获取不同用户的权限清单
    getPermissionsByRole(params) {
        return get('/school/get_permissionsByRole', params);
    },
    getWebRoles(params){
        return post('/authority/getWebRoles', params)
    },
    getAuthoritySelect(){
        return post('/authority/getAuthoritySelect')
    },
    getWebRolesAuthority(params){
        return post('/authority/getWebRolesAuthority',params)
    },
    getWebRolesAllAuthority(params){
        return post('/authority/getWebRolesAllAuthority',params)
    },
    removeWebRoles(params){
        return post('/authority/removeWebRoles',params)
    },
    removeUser(params){
        return post('/authority/removeUser',params)
    },
    getExistedAuthList(){
        return post('/authority/getExistedAuthList')
    },
    getDefaultCheckedKeysList(params){
        return post('/authority/getDefaultCheckedKeysList',params)
    },
    changeDefaultCheckedKeysList(params){
        return post('/authority/changeDefaultCheckedKeysList',params)
    },
    deleteWebRoleAuthority(params){
        return post('/authority/deleteWebRoleAuthority',params)
    },
    getAddWebRolesList(){
        return post('/authority/getAddWebRolesList')
    },
    addWebRoles(params){
        return post('/authority/addWebRoles',params)
    }
}

export default authority